import React, { Component } from 'react'

import ImplantologiePage from '../containers/ImplantologiePage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const Implantologie = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentImplantologie}
    >
      <ImplantologiePage />
    </PrimaryLayout>
  )
}

export default Implantologie
